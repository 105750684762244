<template>
  <div
    class="group my-4 flex h-auto cursor-pointer flex-nowrap bg-white shadow-showcase transition-colors hover:bg-gradient-light-green"
    @mouseenter="setIcon('youtubeplay')"
    @mouseleave="setIcon('youtubeplay-blue')"
  >
    <div
      class="relative w-28 min-w-28 overflow-hidden bg-grey"
      :style="{ maxHeight: '120px' }"
    >
      <img
        v-if="props.video?.properties?.ytThumbnail"
        :src="props.video.properties.ytThumbnail"
        loading="lazy"
        :width="props.video.properties.dimensions?.width"
        :height="props.video.properties.dimensions?.height"
        class="size-full object-cover transition-all group-hover:scale-110"
      >
      <UiIcon
        width="28"
        height="20"
        :name="iconName"
        class="absolute bottom-2 left-3 text-white transition-colors group-hover:text-red-550"
      />
    </div>
    <div class="flex w-52 flex-col justify-between px-4 py-7">
      <p class="line-clamp-3 min-h-16 text-sm font-bold text-primary transition-all group-hover:text-white">
        {{ props.video?.properties?.title }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import UiIcon from '../../UiIcon/UiIcon.vue'
import type { UiTextmediaYtItemVideoProps } from '../../UiTextmediaYt/UiTextmediaYt.types'

interface UiTextmediaYtItemProps {
  video: UiTextmediaYtItemVideoProps
}

const props = defineProps<UiTextmediaYtItemProps>()

const iconName = ref('youtubeplay-blue')

function setIcon(name: string) {
  iconName.value = name
}
</script>
